@import 'common/styles/global';

.group-header {
  margin: $MARGIN_XS $MARGIN_MD 0 $MARGIN_MD;

  .title-panel {
    display: flex;
    align-items: center;

    .title {
      font-weight: $FONT_WEIGHT_SEMIBOLD;
      font-size: $FONT_SIZE_H2;
      margin-left: $MARGIN_XS;
    }

    .change-icon {
      width: 16px;
      height: 16px;
      margin-left: $MARGIN_XS;
    }

    .details {
      font-size: $FONT_SIZE_H4;
      margin-left: $MARGIN_XS;
      margin-right: $MARGIN_XS;
      white-space: nowrap;
      max-width: 800px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.client-header {
  display: flex;
  align-items: center;

  .panelRight {
    margin-left: $MARGIN_XS;

    .title {
      font-weight: $FONT_WEIGHT_SEMIBOLD;
      font-size: $FONT_SIZE_H2;
      white-space: nowrap;
      max-width: 370px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .change-icon {
      width: 16px;
      height: 16px;
      margin-left: $MARGIN_XS;
    }

    .panelLeft {
      .avatar {
        height: 50%;
        width: 50%;
      }
    }

    .details {
      font-size: $FONT_SIZE_H4;

      .seperator {
        color: $HEADER_ITEM_SEPERATOR_COLOR;
        margin: 0 $MARGIN_XXS;
      }
    }
  }
}
