@import 'core/styles/variables';

.content {
  display: block;

  &.nodivider {
    hr {
      display: none !important;
    }

    > div {
      > div {
        padding: $offset-small !important;
      }
    }
  }
}
