@import 'common/styles/global';

.health-concerns-container {
  background-color: #fff;
  border: 1px solid $FIELD_BORDER_COLOR;
  border-radius: $BASE_UNIT;
  display: flex;
  min-height: 600px;

  .health-concerns-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: $MARGIN_MD;
    padding-top: $MARGIN_MD;
    width: 100%;
  }

  .timeline-card-content {
    width: 500px;
  }

  .timeline-card-content > header {
    margin: 0 12px;
  }

  .timeline-horizontal-container {
    margin: 0;
    padding: 0;
  }

  .timeline-horz-item-container {
    margin: 0 4px;
  }

  .timeline-horz-card-wrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 4px;
    padding: 0;
    outline: none;
  }

  .timeline-horz-card-wrapper > section {
    max-height: 250px;
  }

  .timeline-main-wrapper {
    justify-content: unset;
  }

  .timeline-main-wrapper > div {
    position: relative;
  }

  div:has(> .timeline-horz-card-wrapper) {
    width: 100%;
    margin-top: -100px;
    margin-bottom: 100px;
  }

  div:has(> .timeline-controls) {
    position: absolute;
    top: 150px;
  }

  .timeline-item-title.active {
    background-color: transparent;
    border: 1px solid #c50;
  }

  .timeline-card-content.active {
    outline: none;
    border: 1px solid #c50;
  }
}
