@import 'core/styles/variables';

.client-record-details {
  .tabList {
    .tabs {
      min-height: 36px;
      margin-top: 24px;

      &.wrap {
        .MuiTabs-flexContainer {
          flex-wrap: wrap;
        }
      }

      .tab {
        background-color: #f6f6f6;
        color: $dark;
        font-size: 11px;
        font-weight: bold;
        padding: 5px 16px !important;
        margin: 0 7px 0 0 !important;
        border: 0;
        text-transform: capitalize;
        min-height: 36px;
        vertical-align: bottom;

        &.Mui-selected {
          background-color: #e5e5e5 !important;
        }
      }
    }
  }
}
