.upload-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .upload-input {
    clip: 'rect(0 0 0 0)';
    clip-path: inset(50%);
    height: '1px';
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: '1px';
  }
}
