@import 'common/styles/global';
@import 'core/styles/variables';

.search-group {
  .head-cell {
    font-weight: bold !important;

    .identifier {
      font-weight: bold;
      font-size: $font-size-regular;
    }
  }

  .search-content {
    .error {
      margin-top: 1rem;
    }

    .divider {
      margin-bottom: $MARGIN_MD;
      margin-top: $MARGIN_MD;
    }

    .actions {
      text-align: end;
      margin-top: 1rem;

      .button {
        text-transform: capitalize;

        &.search {
          margin-left: 1rem;
        }
      }
    }
  }

  .search-results {
    .table {
      min-width: 600px;

      .row {
        .cell {
          border-bottom: none;
        }
      }

      .special-row {
        &:last-child td,
        &:last-child th {
          border: 0;
        }

        .typography {
          font-size: $font-size-regular;
        }
      }
    }

    .footer {
      .row {
        .cell {
          border-bottom: none !important;

          .add-group {
            .container {
              text-align: end;
              margin-top: 0.5rem;
              position: relative;

              .button {
                text-transform: capitalize;

                &.primary {
                  position: relative;
                  bottom: 0;
                }
              }
            }
          }
        }

        .pagination {
          border-bottom: none !important;
        }
      }
    }
  }
}
