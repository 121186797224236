@import 'common/styles/global';

.running-notes-container {
  background-color: #fff;
  border: 1px solid $FIELD_BORDER_COLOR;
  border-radius: $BASE_UNIT;
  display: flex;
  margin: calc($MARGIN_MD / 2) $MARGIN_MD;
  min-height: 600px;

  .running-notes-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: $MARGIN_MD;
    padding-top: $MARGIN_MD;
    width: 100%;
  }
}

.running-note-box {
  margin: 0 $MARGIN_XS;
  padding: $MARGIN_SM $MARGIN_MD;
  border: 1px solid $FIELD_BORDER_COLOR;
  border-radius: $BASE_UNIT;
}
