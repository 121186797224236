@import 'common/styles/global';
@import 'core/styles/variables';

.top-nav {
  .bar {
    background-color: $BACKGROUND;
    box-shadow: none;
    display: inline;

    .icon {
      color: $neutral_600 !important;
      height: 48px;
      width: 48px;
    }
  }
}

.text {
  color: $neutral_600 !important;

  span {
    padding-right: 5px;

    &.label {
      position: relative;
      bottom: 3px;
    }
  }
}
