/* MIS Style Guide */

// Colors
$WHITE: #fff;
$BLACK: #000;
$BACKGROUND: $WHITE;
$FOREGROUND: #fff;
$PRIMARY: #c50;
$SECONDARY: #333;
$DISABLED: #ccc;
$DISABLED_TEXT: #858585;
$ACTIVE_BUTTON: #b64f04;
$HOVERED_BUTTON: #cc5500ca;
$HOVERED_MENUITEM: #cc550012;
$ACCENT_1: #a5c0e3;
$ACCENT_2: #f7d847;
$ACCENT_3: #9ad1c0;
$ACCENT_4: #e98459;
$ACCENT_5: #b4a5e3;
$ACCENT_6: #ab8372;
$ACCENT_7: #457b9d;
$ACCENT_8: #606c38;
$ALERT_PRIMARY: #cfe2ff;
$ALERT_SUCCESS: #d1e7dd;
$ALERT_FAILURE: #f8d7da;
$ALERT_WARNING: #fef3cd;
$FONT_BODY_COLOR: #333;
$FIELD_BORDER_COLOR: #cbcbcb;
$FIELD_DISABLED_BG_COLOR: #f0f0f0;
$FIELD_ERROR_TEXT: #ba1b1b;
$INNER_LEFT_SIDEBAR_BACKGROUND: #f5efea;
$CHIP_ACTIVE_BG_COLOR: #2296c7;
$CHIP_GOV_AGENCY_EXTERNAL_BG: #046c8e;
$CHIP_GOV_AGENCY_ACTIVE_BG: #63a103;
$CHIP_GOV_AGENCY_TYPES_BG: #f4f4f4;
$CHIP_BG_COLOR: #f4f4f4;
$CHIP_HEADER_ACTIVE_BG_COLOR: #e0f3e6;
$CHIP_HEADER_ACTIVE_PILL_COLOR: #60bf7c;
$CHIP_HEADER_INACTIVE_BG_COLOR: #e3e2e0;
$CHIP_HEADER_INACTIVE_PILL_COLOR: #91918e;
$HEADER_ITEM_SEPERATOR_COLOR: #eee;
$HEADER_ICON_BACKGROUND_COLOR: #00000017;
$TEMPLATE_SECTION_BG_COLOR: #dfe3e7;
$LOGIN_BOX_BORDER_COLOR: #eee;
