@import './colors';
@import './typography';

$BASE_UNIT: 8px;
$BACKGROUND: $BACKGROUND;
$MARGIN_XXXS: calc($BASE_UNIT * 0.25);
$MARGIN_XXS: calc($BASE_UNIT * 0.5);
$MARGIN_XS: calc($BASE_UNIT);
$MARGIN_S: calc($BASE_UNIT * 1.5);
$MARGIN_SM: calc($BASE_UNIT * 2);
$MARGIN_MD: calc($BASE_UNIT * 3);
$MARGIN_LG: calc($BASE_UNIT * 4);
$MARGIN_XL: calc($BASE_UNIT * 5);
$MARGIN_XXL: calc($BASE_UNIT * 6);
$PADDING_XXXS: calc($BASE_UNIT * 0.25);
$PADDING_XXS: calc($BASE_UNIT * 0.5);
$PADDING_XS: calc($BASE_UNIT);
$PADDING_SM: calc($BASE_UNIT * 2);
$PADDING_MD: calc($BASE_UNIT * 3);
$PADDING_LG: calc($BASE_UNIT * 4);
$PADDING_XL: calc($BASE_UNIT * 5);
$PADDING_XXL: calc($BASE_UNIT * 6);
$FONT_SIZE_H1: calc($BASE_UNIT * 4);
$FONT_SIZE_H2: calc($BASE_UNIT * 3);
$FONT_SIZE_H3: calc($BASE_UNIT * 2.5);
$FONT_SIZE_H4: calc($BASE_UNIT * 2);
$FONT_SIZE_H5: calc($BASE_UNIT * 1.75);
$FONT_SIZE_H6: calc($BASE_UNIT * 1.75);
$FONT_BODY_REGULAR: calc($BASE_UNIT * 1.75);
$FONT_BODY_LARGE: calc($BASE_UNIT * 2);
$BUTTON_REGULAR_HEIGHT: calc($BASE_UNIT * 5);
$BUTTON_SMALL_HEIGHT: calc($BASE_UNIT * 4);
$TEXT_ALIGN_CENTER: 'center';
$TEXT_ALIGN_LEFT: 'left';
$TEXT_ALIGN_RIGHT: 'right';
$BLACK: $BLACK;
$WHITE: $WHITE;
$DISABLED_TEXT: $DISABLED_TEXT;
$FIELD_BORDER_COLOR: $FIELD_BORDER_COLOR;
$CHIP_GOV_AGENCY_EXTERNAL_BG: $CHIP_GOV_AGENCY_EXTERNAL_BG;
$CHIP_GOV_AGENCY_ACTIVE_BG: $CHIP_GOV_AGENCY_ACTIVE_BG;
$CHIP_GOV_AGENCY_TYPES_BG: $CHIP_GOV_AGENCY_TYPES_BG;
$CHIP_BG_COLOR: $CHIP_BG_COLOR;
$CHIP_HEADER_ACTIVE_BG_COLOR: $CHIP_HEADER_ACTIVE_BG_COLOR;
$CHIP_HEADER_ACTIVE_PILL_COLOR: $CHIP_HEADER_ACTIVE_PILL_COLOR;
$CHIP_HEADER_INACTIVE_BG_COLOR: $CHIP_HEADER_INACTIVE_BG_COLOR;
$CHIP_HEADER_INACTIVE_PILL_COLOR: $CHIP_HEADER_INACTIVE_PILL_COLOR;
$HEADER_ICON_BACKGROUND_COLOR: $HEADER_ICON_BACKGROUND_COLOR;
$HEADER_ITEM_SEPERATOR_COLOR: $HEADER_ITEM_SEPERATOR_COLOR;
$BASE_FONT_FAMILY: $FONT_FAMILY_REGULAR;
$BASE_FONT_WEIGHT: $FONT_WEIGHT_REGULAR;
$BASE_FONT_SIZE: $FONT_SIZE_REGULAR;
$BASE_FONT_COLOR: $FONT_BODY_COLOR;
$FONT_WEIGHT_REGULAR: $FONT_WEIGHT_REGULAR;
$FONT_WEIGHT_MEDIUM: $FONT_WEIGHT_MEDIUM;
$FONT_WEIGHT_SEMIBOLD: $FONT_WEIGHT_SEMIBOLD;
$TEMPLATE_SECTION_BG_COLOR: $TEMPLATE_SECTION_BG_COLOR;
$LOGIN_BOX_BORDER_COLOR: $LOGIN_BOX_BORDER_COLOR;

:export {
  BASE_UNIT: $BASE_UNIT;
  BACKGROUND: $BACKGROUND;
  FOREGROUND: $FOREGROUND;
  WHITE: $WHITE;
  BLACK: $BLACK;
  BASE_FONT_FAMILY: $FONT_FAMILY_REGULAR;
  BASE_FONT_WEIGHT: $FONT_WEIGHT_REGULAR;
  BASE_FONT_SIZE: $FONT_SIZE_REGULAR;
  BASE_FONT_COLOR: $FONT_BODY_COLOR;
  FONT_WEIGHT_REGULAR: $FONT_WEIGHT_REGULAR;
  FONT_WEIGHT_MEDIUM: $FONT_WEIGHT_MEDIUM;
  FONT_WEIGHT_SEMIBOLD: $FONT_WEIGHT_SEMIBOLD;
  FONT_SIZE_H1: $FONT_SIZE_H1;
  FONT_SIZE_H2: $FONT_SIZE_H2;
  FONT_SIZE_H3: $FONT_SIZE_H3;
  FONT_SIZE_H4: $FONT_SIZE_H4;
  FONT_SIZE_H5: $FONT_SIZE_H5;
  FONT_SIZE_H6: $FONT_SIZE_H6;
  FONT_BODY_REGULAR: $FONT_BODY_REGULAR;
  FONT_BODY_LARGE: $FONT_BODY_LARGE;
  BUTTON_PRIMARY_BG_COLOR: $PRIMARY;
  BUTTON_PRIMARY_COLOR: $WHITE;
  BUTTON_PRIMARY_PRESSED_BG_COLOR: $ACTIVE_BUTTON;
  BUTTON_PRIMARY_HOVERED_BG_COLOR: $HOVERED_BUTTON;
  BUTTON_SECONDARY_BG_COLOR: $WHITE;
  BUTTON_SECONDARY_COLOR: $PRIMARY;
  BUTTON_DISABLED_BG_COLOR: $DISABLED;
  BUTTON_DISABLED_COLOR: $DISABLED_TEXT;
  BUTTON_SUCCESS_BG_COLOR: #16a34a; // May be removed
  BUTTON_REGULAR_FONT_FAMILY: $FONT_FAMILY_REGULAR;
  BUTTON_REGULAR_FONT_WEIGHT: $FONT_WEIGHT_REGULAR;
  BUTTON_REGULAR_HEIGHT: calc($BASE_UNIT * 5);
  BUTTON_SMALL_HEIGHT: calc($BASE_UNIT * 4);
  LINK_PRIMARY_COLOR: $PRIMARY;
  TEXT_ALIGN_CENTER: 'center';
  TEXT_ALIGN_LEFT: 'left';
  TEXT_ALIGN_RIGHT: 'right';
  FIELD_BASE_HEIGHT: calc($BASE_UNIT * 3);
  FIELD_BORDER_RADIUS: calc($BASE_UNIT * 0.5);
  FIELD_BORDER_COLOR: $FIELD_BORDER_COLOR;
  FIELD_BORDER_COLOR_HOVER: $SECONDARY;
  FIELD_BORDER_COLOR_DISABLED: $FIELD_BORDER_COLOR;
  FIELD_DISABLED_COLOR: $FIELD_BORDER_COLOR;
  FIELD_DISABLED_BG_COLOR: $FIELD_DISABLED_BG_COLOR;
  FIELD_LABEL_FONT_SIZE: calc($BASE_UNIT * 1.75);
  FIELD_ERROR_TEXT: $FIELD_ERROR_TEXT;
  FIELD_PLACEHOLDER_FONT_COLOR: $DISABLED;
  FIELD_LABEL_COLOR: $SECONDARY;
  MENU_OPTION_HOVER_BACKGROUND: $HOVERED_MENUITEM;
  FIELD_RADIO_LABEL_UNSELECTED: $FIELD_BORDER_COLOR;
  FIELD_RADIO_LABEL_SELECTED: $PRIMARY;
  INNER_LEFT_SIDEBAR_BACKGROUND: $INNER_LEFT_SIDEBAR_BACKGROUND;
  CHIP_ACTIVE_BG_COLOR: $CHIP_ACTIVE_BG_COLOR;
  CHIP_GOV_AGENCY_EXTERNAL_BG: $CHIP_GOV_AGENCY_EXTERNAL_BG;
  CHIP_GOV_AGENCY_ACTIVE_BG: $CHIP_GOV_AGENCY_ACTIVE_BG;
  CHIP_GOV_AGENCY_TYPES_BG: $CHIP_GOV_AGENCY_TYPES_BG;
  CHIP_BG_COLOR: $CHIP_BG_COLOR;
  CHIP_HEADER_ACTIVE_BG_COLOR: $CHIP_HEADER_ACTIVE_BG_COLOR;
  CHIP_HEADER_ACTIVE_PILL_COLOR: $CHIP_HEADER_ACTIVE_PILL_COLOR;
  CHIP_HEADER_INACTIVE_BG_COLOR: $CHIP_HEADER_INACTIVE_BG_COLOR;
  CHIP_HEADER_INACTIVE_PILL_COLOR: $CHIP_HEADER_INACTIVE_PILL_COLOR;
  HEADER_ICON_BACKGROUND_COLOR: $HEADER_ICON_BACKGROUND_COLOR;
  HEADER_ITEM_SEPERATOR_COLOR: $HEADER_ITEM_SEPERATOR_COLOR;
  TEMPLATE_SECTION_BG_COLOR: $TEMPLATE_SECTION_BG_COLOR;
  LOGIN_BOX_BORDER_COLOR: $LOGIN_BOX_BORDER_COLOR;
  MARGIN_XXXS: $MARGIN_XXXS;
  MARGIN_XXS: $MARGIN_XXS;
  MARGIN_XS: $MARGIN_XS;
  MARGIN_S: $MARGIN_S;
  MARGIN_SM: $MARGIN_SM;
  MARGIN_MD: $MARGIN_MD;
  MARGIN_LG: $MARGIN_LG;
  MARGIN_XL: $MARGIN_XL;
  MARGIN_XXL: $MARGIN_XXL;
  PADDING_XXXS: $PADDING_XXXS;
  PADDING_XXS: $PADDING_XXS;
  PADDING_XS: $PADDING_XS;
  PADDING_SM: $PADDING_SM;
  PADDING_MD: $PADDING_MD;
  PADDING_LG: $PADDING_LG;
  PADDING_XL: $PADDING_XL;
  PADDING_XXL: $PADDING_XXL;
}
