.ql-mention-list-container {
  max-height: 300px;
  overflow: auto;
  width: auto;
  min-width: 300px;
  white-space: nowrap;
}

.ql-undo {
  width: 24px !important;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12.5 8c-2.65 0-5.05 1-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88c3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8"/></svg>') !important;
}

.ql-redo {
  width: 24px !important;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18.4 10.6C16.55 9 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16a8 8 0 0 1 7.6-5.5c1.95 0 3.73.72 5.12 1.88L13 16h9V7z"/></svg>') !important;
}

.quill-editor .ql-editor {
  background: none;
}

.charting-entry-section {
  max-height: 1000px;
  overflow-y: auto;
}

.filepond--wrapper,
.filepond--drop-label {
  height: 100% !important;
}

.dropzone.filepond--root {
  height: 100%;
  opacity: 0.7;
  border-style: dashed;
}

.dropzone.filepond--root .filepond--list-scroller {
  display: none;
}

.attachment-list.filepond--root .filepond--list-scroller {
  margin-top: 4;
  margin-bottom: 4;
}

.attachment-list.filepond--root .filepond--list-scroller li {
  width: fit-content;
}

.attachment-list.filepond--root .filepond--browser,
.attachment-list.filepond--root .filepond--credits,
.attachment-list.filepond--root .filepond--drop-label {
  display: none;
}
