
.dock-panel.dock-style-custom .dock-tab.dock-tab-active {
  background: #fff;
  color: #cc5500;
}

.dock-panel.dock-style-custom .dock-tab-hit-area {
  /* cover the border area */
  left: -1px;
  right: -1px;
}

.dock-panel.dock-style-custom .dock-panel-max-btn, .dock-panel.dock-style-custom .dock-panel-min-btn {
  opacity: 1;
}

.dock-panel.dock-style-custom .dock-content .dock-tabpane {
  overflow-y: scroll;
}
