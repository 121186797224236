.tabbed-content-container {
  display: flex;
  border-radius: 8px;
  border: 1px solid #cbcbcb;
  min-height: 600px;

  .MuiTabs-root {
    border-radius: 8px 0 0 8px;
  }

  button.MuiTab-root {
    display: flex;
    width: 100%;
    text-transform: capitalize;
    text-align: left;
    opacity: 1;
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.tabs-container {
  width: 240px;
  border-radius: 8px 0 0 8px;
  margin: 2rem 0;
}

.tabs-content {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
}
