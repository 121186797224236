.image-content-container {
  display: flex;
  margin: 24px;
  border-radius: 8px;
  border: 1px solid #cbcbcb;
  min-height: 300px;
}

.image-container {
  width: 240px;
  border-radius: 8px 0 0 8px;
}

.main {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
}
