@import 'common/styles/global';

.client-text {
  color: #c50 !important;
  font-size: $FONT_BODY_REGULAR;
  line-height: 40px;
}

.client-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.icon {
  height: 40px;
  padding: $PADDING_XS;
}

.icon:hover {
  cursor: pointer;
}
