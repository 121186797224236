@import 'common/styles/global';

.section-header {
  background-color: $WHITE;
  padding-left: $PADDING_MD;
  padding-right: $PADDING_MD;
  position: sticky;
  top: 0;
  z-index: 999;
}
