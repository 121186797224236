@import 'common/styles/global';
@import 'core/styles/variables';

.provider-staff-record-details {
  .provider-staff-section-container {
    padding-top: $PADDING_XS;
    padding-left: $PADDING_MD;
    padding-right: $PADDING_MD;
  }

  .tabList {
    .tabs {
      min-height: 36px;
      margin-top: 24px;

      &.wrap {
        .MuiTabs-flexContainer {
          flex-wrap: wrap;
        }
      }

      .tab {
        background-color: #f6f6f6;
        color: $dark;
        font-size: 11px;
        font-weight: bold;
        padding: 5px 16px !important;
        margin: 0 7px 0 0 !important;
        border: 0;
        text-transform: capitalize;
        min-height: 36px;
        vertical-align: bottom;

        &.Mui-selected {
          background-color: #e5e5e5 !important;
        }
      }
    }
  }
}
