/* MIS Style Guide */

// Typography
$FONT_FAMILY_REGULAR: 'Inter-Regular', 'Inter', sans-serif;
$FONT_WEIGHT_REGULAR: 400;
$FONT_SIZE_REGULAR: '14px';
$FONT_FAMILY_MEDIUM: 'Inter', sans-serif;
$FONT_WEIGHT_MEDIUM: 500;
$FONT_FAMILY_SEMIBOLD: 'Inter-SemiBold', 'Inter Semi Bold', 'Inter', sans-serif;
$FONT_WEIGHT_SEMIBOLD: 650;
