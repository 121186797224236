@import 'common/styles/global';

.gov-agency-record-header {
  margin: 0 $MARGIN_MD;

  .panel {
    display: inline-block;

    &.left {
      vertical-align: top;
      padding-right: $PADDING_MD;
      margin-top: -$MARGIN_MD;

      .avatar {
        display: block;
        width: 120px;
        height: 120px;

        .icon {
          color: $WHITE;
        }
      }
    }

    &.right {
      width: 75%;
      vertical-align: top;
      margin-bottom: $MARGIN_MD;

      .title {
        width: auto;
        font-weight: 650;
        font-size: $FONT_SIZE_H1;
        text-align: $TEXT_ALIGN_LEFT;
        line-height: $MARGIN_XL;
        padding-right: $PADDING_XS;
      }

      .agencyChip {
        font-weight: 650;
        font-size: $FONT_SIZE_H1;
        text-align: $TEXT_ALIGN_LEFT;
        line-height: $MARGIN_XL;
        padding-right: $PADDING_XS;
      }

      .name {
        font-size: 14px;

        &.bottom {
          padding-top: $PADDING_XXS;
        }
      }

      .MuiStack-root {
        display: flex;
        flex-direction: row;
        align-items: center;

        .MuiChip-root {
          background-color: $CHIP_GOV_AGENCY_TYPES_BG;
          color: $BLACK;
          margin-left: $MARGIN_XXXS;
          margin-right: $MARGIN_XXXS;

          &.active-chip {
            background-color: $CHIP_GOV_AGENCY_ACTIVE_BG;
            color: $WHITE;
          }

          &.external-chip {
            color: $WHITE;
            background-color: $CHIP_GOV_AGENCY_EXTERNAL_BG;
          }
        }
      }

      .identifier-container {
        display: flex;
        padding-top: $MARGIN_XS;

        .MuiTypography-root {
          display: inline;
          vertical-align: top;
        }
      }
    }
  }
}
