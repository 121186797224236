@import 'common/styles/global';

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* Scrollbar */
::-webkit-scrollbar {
  /* width */
  width: 8px;
}

::-webkit-scrollbar-track {
  /* Track */
  background: #f1f1f1;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  /* Handle */
  background: #888;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  /* Handle on hover */
  background: #555;
}

body {
  background-color: $BACKGROUND !important;
}
