@import 'common/styles/global';

.rc-card-text {
  align-items: flex-start !important;
}

h1 {
  font-family: $BASE_FONT_FAMILY;
}

h2 {
  font-family: $BASE_FONT_FAMILY;
  font-weight: normal !important;
}
