.quill.non-clinical-note {
  .ql-container {
    border: none;

    .ql-editor {
      padding-left: 0;
      min-height: 32px;
    }
  }
}
