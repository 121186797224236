$breakpoints: (
  xs-lower: 575px,
  xs: 576px,
  sm-lower: 767px,
  sm: 768px,
  md-lower: 991px,
  md: 992px,
  lg: 1200px,
);
$background: #e5e5e5;
$white: #fff;
$black: #000;
$primary: #1e22fb;
$dark: #333;
$neutral_600: #4b5563;
$neutral_200: #e5e7eb;
$offset-none: 0;
$offset-small: 1rem;
$offset-medium: 1.5rem;
$offset-regular: 2rem;
$offset-large: 2.5rem;
$offset-pixels-xs: 5px;
$offset-pixels-sm: 10px;
$offset-pixels-md: 20px;
$offset-pixels-rg: 30px;
$offset-pixels-lg: 40px;
$offset-pixels-xl: 50px;
$offset-custom1: 100vh;
$font-size-small: 12px;
$font-size-regular: 14px;
$font-size-medium: 24px;
$font-size-large: 24px;
$font-weight-xsmall: 100;
$font-weight-small: 200;
$font-weight-medium: 300;
$font-weight-large: 400;
$font-weight-xlarge: 500;
$font-weight-xxlarge: 600;
$width-one: 45%;
$width-two: 50%;
$width-three: 70%;
$width-four: 80%;
$width-five: 90%;
