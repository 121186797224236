@import 'common/styles/global';
@import 'core/styles/variables';

.card-template {
  display: block;
  position: relative;

  .divider {
    margin-bottom: $MARGIN_SM;
    margin-top: $MARGIN_SM;
  }

  .expand {
    position: absolute;
    right: 25px;
    top: 0;

    .icon {
      color: $dark;
    }
  }
}
