@import 'common/styles/global';

.provider-staff-record-header {
  margin: 0 $MARGIN_MD;

  .panel {
    display: inline-block;

    &.left {
      vertical-align: top;
      padding-right: $PADDING_MD;
      margin-top: -$MARGIN_MD;

      .avatar {
        display: block;
        width: 120px;
        height: 120px;

        .icon {
          color: $WHITE;
        }
      }
    }

    &.right {
      width: 75%;
      vertical-align: top;
      margin-bottom: $MARGIN_MD;

      .title {
        width: auto;
        font-weight: 650;
        font-size: $FONT_SIZE_H1;
        text-align: $TEXT_ALIGN_LEFT;
        line-height: $MARGIN_XL;
        padding-right: $PADDING_XS;
      }

      .key-title {
        font-size: 14px;

        &.bottom {
          padding-top: $PADDING_XXS;
        }
      }

      .MuiStack-root {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .key-value-container {
        display: flex;
        padding-top: $MARGIN_XS;

        .key-value-column {
          padding-right: $MARGIN_MD;
        }
      }
    }
  }
}
