@import 'common/styles/global';

.charting-view {
  display: flex;
  justify-content: space-between;
  margin: $MARGIN_MD;
  border-radius: $BASE_UNIT;
  min-height: 600px;
}

.charting-list-view {
  width: 320px;
  height: 600px;
  border: 1px solid $FIELD_BORDER_COLOR;
  border-radius: $BASE_UNIT;
  background-color: $WHITE;

  & .ql-container {
    border: none;
    font-weight: bold;

    & .ql-editor {
      min-height: auto;
      padding: 0;
      margin: 0;
    }
  }
}

.charting-content-view {
  flex: 1;
  border: 1px solid $FIELD_BORDER_COLOR;
  margin-left: $MARGIN_MD;
  background-color: $WHITE;
  border-radius: $BASE_UNIT;
}

.quill.canvas {
  .ql-container {
    .ql-editor {
      min-height: 800px;
    }
  }
}
