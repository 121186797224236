@import 'common/styles/global';

.router-app {
  display: flex;
  min-height: 100vh;
}

.left-pane {
  background-color: $WHITE;
  border-right: 1px solid rgba(0 0 0 / 12%);
}

.right-pane {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
