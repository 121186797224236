@import 'core/styles/variables';

.governance-agency-list {
  .container {
    background: $white;
    border-radius: 6px;
    margin: $offset-medium;

    &.special {
      padding: $offset-regular !important;
    }

    .field {
      .MuiTextField-root {
        margin-top: 16px;
        margin-right: 16px;
        width: 25ch;
      }
    }

    .field2 {
      text-align: end;
      margin-top: $offset-small;

      .button {
        text-transform: capitalize;

        &.special {
          margin-left: $offset-small !important;
        }
      }
    }

    .table-container {
      padding: $offset-small !important;

      .content {
        background: $white;
        border-radius: 6px;
        padding: 0.5rem;
        margin: 0.5rem;
        padding-top: $offset-small;
        padding-bottom: $offset-none;
      }

      .table {
        min-width: 600px;

        .row {
          .cell {
            &.special {
              max-width: 300px !important;
            }

            .typography {
              font-size: 14px;
            }
          }
        }

        .footer {
          .row {
            .cell {
              border-bottom: none !important;

              .cell-container {
                text-align: end;
                margin-top: 0.5rem;
                position: relative;

                .button {
                  text-transform: capitalize;
                  position: relative;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .divider {
      margin: $offset-regular -2rem $offset-regular -2rem;
    }
  }
}
