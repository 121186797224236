@import './variables';

* {
  font-family: Inter, sans-serif !important;
}

body {
  margin: $offset-none;
  height: 100vh;
}

h1 {
  margin-top: $offset-none;
}

h5 {
  margin-top: $offset-none;
  margin-bottom: $offset-medium;
  font-size: $font-size-medium;
  font-weight: $font-weight-xlarge;
}

.main-container {
  background: $background;
}

.card {
  background: $white;
  border-radius: 10px;
  padding: $offset-large;
  width: $width-one;
}

.widecard {
  background: $white;
  border-radius: 10px;
  padding: $offset-large;
  width: $width-three;
}

button {
  &.notifications {
    background-color: $dark !important;
    width: $width-five;
    margin: 20px auto;
    font-size: x-small;
    position: relative;
    left: 10px;
  }
}

.center-align {
  margin: auto;
}

.modal-title {
  color: $neutral_600;
  text-align: center;
}

.separator {
  width: $width-four;
  border-bottom: 1px solid $neutral_200;
  margin-bottom: $offset-medium;
  margin-left: auto;
  margin-right: auto;
}

.timeout-modal-container {
  position: absolute;
  top: $width-two;
  left: $width-two;
  transform: translate(-$width-two, -$width-two);
  width: $width-two;
  background-color: $white;
  outline: none;
  box-shadow: 24;
  border-radius: 10px;
  padding: $offset-medium;
}

.ql-editor {
  min-height: 130px;
}

.quill.ql-error {
  .ql-toolbar {
    border-bottom: 1px solid red;
  }

  .ql-container {
    border-left: 1px solid red;
    border-right: 1px solid red;
    border-bottom: 1px solid red;
  }

  .ql-editor.ql-blank::before {
    color: red;
  }
}

.quill.ql-error-placeholder {
  .ql-editor.ql-blank::before {
    color: red;
  }
}
